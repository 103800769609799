import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [

  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/pages/Home')
  },
  {
    path: '/policy',
    name: 'Policy',
    component: () => import(/* webpackChunkName: "policy" */ '../views/pages/Policy')
  },
  {
    path: '/organizational-chart',
    name: 'OrganizationalChart',
    component: () => import(/* webpackChunkName: "organizational-chart" */ '../views/pages/Chart')
  },
  {
    path: '/vmgo',
    name: 'VMGO',
    component: () => import(/* webpackChunkName: "vmgo" */ '../views/pages/VMGO')
  },
  {
    path: '/news-details',
    name: 'News',
    component: () => import(/* webpackChunkName: "news-details" */ '../views/pages/NewsDetails')
  },
  {
    path: '/history/main-history',
    name: 'MainHistory',
    component: () => import(/* webpackChunkName: "main-history" */ '../views/pages/history/MainHistory')
  },
  {
    path: '/history/calapan-city-history',
    name: 'CalapanHistory',
    component: () => import(/* webpackChunkName: "calapan-city-history" */ '../views/pages/history/CalapanCityHistory')
  },
  {
    path: '/history/bongabong-history',
    name: 'BongabongHistory',
    component: () => import(/* webpackChunkName: "bongabong-history" */ '../views/pages/history/BongabongHistory')
  },
  {
    path: '/chart/main-campus',
    name: 'Main-Chart',
    component: () => import(/* webpackChunkName: "main-chart" */ '../views/pages/chart/MainChart')
  },
  {
    path: '/chart/calapan-city-campus',
    name: 'Calapan-Chart',
    component: () => import(/* webpackChunkName: "calapan-city-chart" */ '../views/pages/chart/CalapanChart')
  },
  {
    path: '/chart/bongabong-campus',
    name: 'Bongabong-Chart',
    component: () => import(/* webpackChunkName: "bongabong-chart" */ '../views/pages/chart/BongabongChart')
  },
  {
    path: '/library-section',
    name: 'Library-Section',
    component: () => import(/* webpackChunkName: "library-section" */ '../views/pages/LibrarySection')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // mode: 'history',
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach((to,from,next)=>{
  if(to.name){
    document.title = to.name+" | "+store.state.abbreviation + " Library";
  }
  else{
    document.title = "Page not exist!";
  }

  next();
})

export default router
