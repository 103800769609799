import { createStore } from 'vuex'

export default createStore({
  state: {
    title: 'Mindoro State University',
    abbreviation: 'MinSU'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
