<template>
  <router-view class="app"  v-bind:style="{ 'background-image': 'url(' + image + ')' }"/>
</template>
<script>

export default {
  name: 'App',
  components: {},
  data:() =>({
    image: require('@/assets/images/banner.jpg'),
  })
}
</script>
<style scoped>
.app{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
</style>